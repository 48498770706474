//=======================| App Configuration |========================//
export default {
	appUrlBackEnd: 'https://hospapi.sislii.com',
	// appUrlBackEnd: 'http://myd.test',
	appLogo: '/static/images/logo.svg', 
	appLogoDark: '/icon.svg', // App Logo,
	brand: 'M&D HOTEL', // Brand Name
	copyrightText: '© Todos los Derechos Reservados | Hecho por', // Copyright Text
	//theme color
	color: {
      	primary: '#1b2831',  
      	accent : '#E21E42',
	}
}
